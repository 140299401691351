/* Triangle share button */
.share-button {
    position: fixed;
    bottom: 0px;
    right: 0px;
    margin: 0;
    border: 0;
    cursor: pointer;
    text-align: center;
    width: 200px;
    height: 62px;
    background-color: #fff;
    color: #000;
    font-family: Arial, Helvetica, sans-serif;
    transform: rotate(-45deg) translate(48px, 61px);
    -moz-transform: rotate(-45deg) translate(48px, 61px);
    padding: 0 0 10px 0;
    z-index: 69;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
}

.modal-content {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 4em;
    border-radius: 10px;
    width: 80vw;
    max-width: 720px;
    text-align: center;
}

.modal-content .close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    font-size: 24px;
    cursor: pointer;
    color: #aaa;
    height: 30px;
    width: 30px;
}

.modal-content h3 {
    margin-bottom: 2em;
}

.modal-content form {
    width: 100%;
    max-width: 420px;
}

.modal-content label {
    display: block;
    margin: 10px 0;
}

.modal-content input,
.modal-content textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-content button {
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-content button[type="button"],
.modal-content button {
    background-color: #f44336;
    color: #fff;
    margin-left: 10px;
}

/* @media (max-width: 600px) {
    .share-button {
        transform: unset;
        top: 0px;
        left: 0px;
        bottom: unset;
        right: unset;
    }
} */